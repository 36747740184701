<template>
  <div />
</template>

<script>
import { useToast } from 'vue-toastification/composition'

export default {
  name: 'OidcSigInCallBack',
  setup() {
    return {
      toastNotification: useToast(),
    }
  },
  created() {
    this.$oidc.completeLogin()
      .then(() => {
        this.$router.push('/home')
      })
      .catch(err => {
        this.toastNotification.error(err.message)
      })
  },
}
</script>
