const emptyCase = {
        CaseID: null,
        CcEmails: "",
        BudgetHours: 0,
        BudgetRemainingHours: "",
        BudgetDollars: 0,
        ClaimFileNo: "",
        ClaimFileType: "",
        ClaimHandler: "",
        IsContactByAccountManager: false,
        OperationsManagerUserID: null,
        IsPreferPhysicalMedia: false,
        CaseDueDate: null,
        DatesToBeConducted: "",
        NetSweepFeasibility: null,
        USPFeasibility: null,
        InjuryDate: null,
        CompletedDate: null,
        InjuryDesc: "",
        InsuredText: "",
        InsuredClientID: null,
        InsuredContact: "",
        MedicalRestrictions: "",
        RedFlags: "",
        VideoSummary: "",
        EmploymentInfoWorkStatus: "",
        HasAttorneyRepresentation: false,
        ScheduledAppts: false,
        AttorneyRepInfo: "",
        Appointments: [{
            ScheduledApptWhen: "",
            ScheduledApptWhere: "",
        }],
        ScheduledApptWhen: "",
        ScheduledApptWhere: "",
        PrimaryRequesterUserID: "",
        RequestedServices: "",
        ReceivedSource: "",
        RequestedServicesOther: null,
        PrimaryCaseAssistantUserID: null,
        SecondaryCaseAssistantUserID: null,
        USPManagerID: null,
        VendorManagerID: null,
        ClientClaimSubTypes: "",
        DateCreated: "",
        DateClosed: "",
        DateReopened: "",
        CreatedByFullname: "",
        ReopenedByFullname: "",
        ClosedByFullname: "",
        Branch: {
            BranchID: "",
            Name: "",
            Address: "",
            City: "",
            StateProvince: "",
            IsDeleted: false,
            ZipCode: "",
            Client: {
                ClientID: "",
                Name: "",
                Subject: {
                    SubjectID: "",
                    FirstName: "",
                    MiddleName: "",
                    LastName: "",
                    Alias: "",
                    SocialSecurityNo: "",
                    DateOfBirth: null,
                    DOBForInput: "",
                    DriversLicenseNo: "",
                    DriversLicenseState: "",
                    Image: "",
                    RelatedImages: [],
                    Age: "",

                    PhotoSource: "",
                    IsPhotoVerified: false,
                    Phone1: "",
                    Phone1Type: "",
                    Phone2: "",
                    Phone2Type: "",
                    SubjectAddress: [{
                        Address1: "",
                        City: "",
                        StateProvince: "",
                        PostalCode: "",
                        Description: "",

                        Address2: "",
                        AddressID: "",
                        AddressIndex: "",
                        Country: "",
                        Name: "",
                        ZipCode: "",
                        Subject: null,
                        SubjectID: "",
                    }],

                    Gender: "",
                    Children: "",
                    Spouse: "",
                    Height: "",
                    Weight: "",
                    Race: "",
                    Hair: "",
                    HairStyle: "",
                    Eyes: "",
                    Glasses: "",
                    VehicleInformation: "",
                    OtherCharacteristics: "",

                    Notes: "",

                    HasPreviousSurveillanceConducted: false,
                    PreviousSurveillanceResults: "",
                    ReasonForSurveillance: "",
                    NotesRegardingThisAssignment: "",
                    PreviousSurveillanceConductedBy: "",
                }
            },
            Requester: {
                UserAccountID: null,
                Name: "",
                Email: "",
                Phone: "",
            }
        },
        Files: [],
    };

export default {
    namespaced: true,
    state: {
        case: JSON.parse(JSON.stringify(emptyCase)),
        readOnly: false,
        clientReadOnly: false,
        allSubjectTabOpen: 0,
        allCaseTabOpen: 0,
        client: {},
    },
    getters: {
        getCurrentClient: state => state.client,
        getIsReadOnly: state => state.readOnly,
        getAllCaseTabOpen: state => state.allCaseTabOpen,
        getAllSubjectTabOpen: state => state.allSubjectTabOpen,
        getClientReadOnly: state => state.clientReadOnly,
        getCase: state => state.case,
        getSubject: state => state.case.Branch.Client.Subject,
        getAppointments: state => state.case.Appointments,
        getRequestedServices: state => state.case.RequestedServices,
        getFiles: state => state.case.Files,
        getAssignment: state => {
            return {
                ClaimFileNo: state.case.ClaimFileNo,
                NetSweepFeasibility: state.case.NetSweepFeasibility,
                USPFeasibility: state.case.USPFeasibility,
                ClaimFileType: state.case.ClaimFileType,
                InsuredText: state.case.InsuredText,
                InsuredClientID: state.case.InsuredClientID,
                InsuredContact: state.case.InsuredContact,
                ClaimHandler: state.case.ClaimHandler,
                IsContactByAccountManager: state.case.IsContactByAccountManager,
                IsPreferPhysicalMedia: state.case.IsPreferPhysicalMedia,
                BudgetHours: Number(state.case.BudgetHours),
                BudgetRemainingHours: state.case.BudgetRemainingHours,
                BudgetDollars: state.case.BudgetDollars,
                CompletedDate: state.case.CompletedDate,
                CaseDueDate: state.case.CaseDueDate || "",
                DatesToBeConducted: state.case.DatesToBeConducted,
                RequestedServicesOther: state.case.RequestedServicesOther,
                RequestedServices: state.case.RequestedServices,
                ReceivedSource: state.case.ReceivedSource,
                ClientClaimSubTypes: state.case.ClientClaimSubTypes,
            }
        },
        getClaimDetail: state => {
            return {
                InjuryDate: state.case.InjuryDate || "",
                InjuryDesc: state.case.InjuryDesc,
                MedicalRestrictions: state.case.MedicalRestrictions,
                RedFlags: state.case.RedFlags,
                VideoSummary: state.case.VideoSummary,
                EmploymentInfoWorkStatus: state.case.EmploymentInfoWorkStatus,
                HasAttorneyRepresentation: state.case.HasAttorneyRepresentation,
                ScheduledAppts: state.case.ScheduledAppts,
                AttorneyRepInfo: state.case.AttorneyRepInfo,
                Appointments: state.case.Appointments ||  [{
                    ScheduledApptWhen: "",
                    ScheduledApptWhere: "",
                }],
            }
        },
        getPostData: state => {
            return {
                OperationsManagerUserID: state.case.OperationsManagerUserID,
                CaseID: state.case.CaseID,
                CcEmails: state.case.CcEmails,
                BranchID: state.case.Branch.BranchID,
                CaseStateCode: state.case.CaseStateCode || state.case.Branch.Client.Subject.SubjectAddress[0].StateProvince,
                BudgetHours: Number(state.case.BudgetHours),
                BudgetDollars: state.case.BudgetDollars,
                ClaimFileNo: state.case.ClaimFileNo,
                ClaimFileType: state.case.ClaimFileType,
                ClaimHandler: state.case.ClaimHandler,
                CaseDueDate: state.case.CaseDueDate,
                DatesToBeConducted: state.case.DatesToBeConducted,
                EmploymentInfoWorkStatus: state.case.EmploymentInfoWorkStatus,
                HasAttorneyRepresentation: state.case.HasAttorneyRepresentation,
                AttorneyRepInfo: state.case.AttorneyRepInfo,
                NetSweepFeasibility: state.case.NetSweepFeasibility,
                USPFeasibility: state.case.USPFeasibility,
                InjuryDate: state.case.InjuryDate,
                InjuryDesc: state.case.InjuryDesc,
                InsuredText: state.case.InsuredText,
                CompletedDate: state.case.CompletedDate,
                InsuredClientID: state.case.InsuredClientID,
                InsuredContact: state.case.InsuredContact,
                MedicalRestrictions: state.case.MedicalRestrictions,
                PrimaryRequesterUserID: state.case.Branch.Requester.UserAccountID,
                RedFlags: state.case.RedFlags,
                VideoSummary: state.case.VideoSummary,
                RequestedServices: state.case.RequestedServices,
                ReceivedSource: state.case.ReceivedSource,
                RequestedServicesOther: state.case.RequestedServicesOther,
                ScheduledAppts: state.case.ScheduledAppts,
                ScheduledApptWhen: state.case.ScheduledApptWhen,
                ScheduledApptWhere: state.case.ScheduledApptWhere,
                IsContactByAccountManager: state.case.IsContactByAccountManager,
                IsPreferPhysicalMedia: state.case.IsPreferPhysicalMedia,
                PrimaryCaseAssistantUserID: state.case.PrimaryCaseAssistantUserID,
                SecondaryCaseAssistantUserID: state.case.SecondaryCaseAssistantUserID,
                USPManagerID: state.case.USPManagerID,
                VendorManagerID: state.case.VendorManagerID,
                ClientClaimSubTypes: state.case.ClientClaimSubTypes,
                Subject: {
                    FirstName: state.case.Branch.Client.Subject.FirstName,
                    MiddleName: state.case.Branch.Client.Subject.MiddleName,
                    LastName: state.case.Branch.Client.Subject.LastName,
                    Alias: state.case.Branch.Client.Subject.Alias,
                    SocialSecurityNo: state.case.Branch.Client.Subject.SocialSecurityNo,
                    DateOfBirth: state.case.Branch.Client.Subject.DateOfBirth || null,
                    DateOfBirthText: state.case.Branch.Client.Subject.DateOfBirthText,
                    DriversLicenseNo: state.case.Branch.Client.Subject.DriversLicenseNo,
                    DriversLicenseState: state.case.Branch.Client.Subject.DriversLicenseState,
                    Phone1: state.case.Branch.Client.Subject.Phone1,
                    Phone1Type: state.case.Branch.Client.Subject.Phone1Type,
                    Phone2: state.case.Branch.Client.Subject.Phone2,
                    Phone2Type: state.case.Branch.Client.Subject.Phone2Type,
                    Gender: state.case.Branch.Client.Subject.Gender,
                    Height: state.case.Branch.Client.Subject.Height,
                    Weight: state.case.Branch.Client.Subject.Weight,
                    Race: state.case.Branch.Client.Subject.Race,
                    Hair: state.case.Branch.Client.Subject.Hair,
                    HairStyle: state.case.Branch.Client.Subject.HairStyle,
                    Eyes: state.case.Branch.Client.Subject.Eyes,
                    Glasses: state.case.Branch.Client.Subject.Glasses,
                    VehicleInformation: state.case.Branch.Client.Subject.VehicleInformation,
                    OtherCharacteristics: state.case.Branch.Client.Subject.OtherCharacteristics,
                    Notes: state.case.Branch.Client.Subject.Notes,
                    Children: state.case.Branch.Client.Subject.Children,
                    Spouse: state.case.Branch.Client.Subject.Spouse,
                    Addresses: state.case.Branch.Client.Subject.SubjectAddress,
                    HasPreviousSurveillanceConducted: state.case.Branch.Client.Subject.HasPreviousSurveillanceConducted,
                    PreviousSurveillanceResults: state.case.Branch.Client.Subject.PreviousSurveillanceResults,
                    ReasonForSurveillance:  state.case.Branch.Client.Subject.ReasonForSurveillance,
                    NotesRegardingThisAssignment:  state.case.Branch.Client.Subject.NotesRegardingThisAssignment,
                    PreviousSurveillanceConductedBy:  state.case.Branch.Client.Subject.PreviousSurveillanceConductedBy,
                }
            }
        }
    },
    mutations: {
        setCurrentClient(state, data) {
            state.client = { ...data }
        },
        setIsReadOnly(state, data) {
            state.readOnly = data;
        },
        setAllCaseTabOpen(state, data) {
            state.allCaseTabOpen = data;
        },
        setAllSubjectTabOpen(state, data) {
            state.allSubjectTabOpen = data;
        },
        setRequestedServices(state, data) {
            state.case.RequestedServices = data;
        },
        setClientReadOnly(state, data) {
            state.clientReadOnly = data;
        },
        setCase(state, data) {
            state.case = { ...data }
        },
        emptyCase(state) {
            state.case =  JSON.parse(JSON.stringify(emptyCase));
        },
        setSubject(state, data) {
            state.case.Branch.Client.Subject = { ...data }
        },
        setUploadUrl(state, data) {
            state.case.Branch.Client.Subject.Image = data;
        }
    },
    actions: {},
}
