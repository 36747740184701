export default {
  namespaced: true,
  state: {
    sessionStart: new Date(localStorage.getItem('session-start')) || 0,
    UserType: localStorage.getItem('userType') || "",
    UserRole: localStorage.getItem('userRole') || "",
    IsLoggedIn: null,
    expires: 400,
    sessionEnds: false,
  },
  getters: {
    getUserType: state => state.UserType,
    getUserRole: state => state.UserRole,
    getExpires: state => state.expires,
  },
  mutations: {
    UPDATE_SESSION_START(state, val) {
      state.sessionStart = val
    },
    setUserType(state, val) {
      localStorage.setItem('userType', val)
      state.UserType = val
    },
    setUserRole(state, val) {
      localStorage.setItem('userRole', val)
      state.UserRole = val
    },
    setLoginStatus(state, val) {
      state.IsLoggedIn = val;
    },
    setExpires(state, val) {
      state.expires = val;
    },
    setSessionEnds(state, val) {
      state.sessionEnds = val;
    },
  },
  actions: {},
}
