export default {
  namespaced: true,
  state: {
    clients: {},
    subTypes: "",
  },
  getters: {
    getCurrentClients: state => state.clients,
    getSubTypes: state => state.subTypes,
  },
  mutations: {
    setCurrentClients(state, data) {
      state.clients = { ...data }
    },
    setSubTypes(state, data) {
      state.subTypes = data
    },
  },
  actions: {
    // async operations
    async fetchClients(state, cookie) {
      const response = await fetch('/api/users/client ', { headers: { Authorization: `Bearer ${cookie}` } })
      const data = await response.json()
      state.commit('setCurrentClients', data)
    },
  },
}
