const emptyCase = {
        ClientName: "",
        RequesterName: "",
        RequesterEmail: "",
        RequesterPhone: "",
        CcEmails: "",

        BudgetHours: 0,
        BudgetDollars: null,
        ClaimFileNo: "",
        ClaimFileType: "",
        ClaimHandler: "",
        IsContactByAccountManager: false,
        IsPreferPhysicalMedia: false,
        CaseDueDate: null,
        DatesToBeConducted: "",
        NetSweepFeasibility: null,
        USPFeasibility: null,
        InjuryDate: null,
        InjuryDesc: "",
        InsuredText: "",
        InsuredContact: "",
        InsuredRepresentative: "",
        MedicalRestrictions: "",
        RedFlags: "",
        EmploymentInfoWorkStatus: "",
        HasAttorneyRepresentation: false,
        ScheduledAppts: false,
        AttorneyRepInfo: "",
        Appointments: [{
            ScheduledApptWhen: "",
            ScheduledApptWhere: "",
        }],
        ScheduledApptWhen: "",
        ScheduledApptWhere: "",
        RequestedServices: [],
        RequestedServicesOther: null,
        HasPreviousSurveillanceConducted: false,
        PreviousSurveillanceResults: "",
        ReasonForSurveillance: "",
        NotesRegardingThisAssignment: "",
        PreviousSurveillanceConductedBy: "",
        Subject: {
            FirstName: "",
            MiddleName: "",
            LastName: "",
            Alias: "",
            SocialSecurityNo: "",
            DateOfBirth: null,
            DOBForInput: "",
            DriversLicenseNo: "",
            DriversLicenseState: "",
            Phone1: "",
            Phone1Type: "",
            Phone2: "",
            Phone2Type: "",
            Gender: "",
            Children: "",
            Spouse: "",
            Height: "",
            Weight: "",
            Race: "",
            Hair: "",
            HairStyle: "",
            Eyes: "",
            Glasses: "",
            VehicleInformation: "",
            OtherCharacteristics: "",
            Notes: "",
            SubjectAddress: {
                Address1: "",
                City1: "",
                StateProvince1: "",
                PostalCode1: "",
                Description1: "",
                Address2: "",
                City2: "",
                StateProvince2: "",
                PostalCode2: "",
                Description2: "",
            },
        },
};

export default {
    namespaced: true,
    state: {
        case: JSON.parse(JSON.stringify(emptyCase)),
    },
    getters: {
        getCase: state => state.case,
        getSubject: state => state.case.Subject,
        getSubjectAddress: state => state.case.Subject.SubjectAddress,
    },
    mutations: {
        setCase(state, data) {
            state.case = { ...data }
        },
        emptyCase(state) {
            state.case =  JSON.parse(JSON.stringify(emptyCase));
        },
        setSubject(state, data) {
            state.case.Subject = { ...data }
        },
        setSubjectAddress(state, data) {
            state.case.Subject.SubjectAddress = { ...data }
        },
    },
    actions: {},
}
