export default {
  namespaced: true,
  state: {
    investigators: {},
  },
  getters: {
    getCurrentInvestigators: state => state.investigators,
  },
  mutations: {
    setCurrentInvestigators(state, data) {
      state.investigators = { ...data }
    },
  },
  actions: {
    // async operations
    async fetchInvestigators(state, cookie) {
      const response = await fetch('api/users/investigator', { headers: { Authorization: `Bearer ${cookie}` } })
      const data = await response.json()
      state.commit('setCurrentInvestigators', data)
    },
  },
}
