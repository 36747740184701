import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import auth from './auth'
import scopes from './scopes'
import users from './users'
import investigators from './investigators'
import clients from './clients'
import allusers from './allusers'
import cases from './cases'
import search from './search'
import notification from './notification'
import video from './video'
import publicCase from './public'
import offline from './offline'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    scopes,
    users,
    investigators,
    clients,
    allusers,
    cases,
    search,
    notification,
    video,
    publicCase,
    offline,
  },
  strict: process.env.DEV,
})
