<template>
  <div 
    id="app" 
    class="h-100" 
    :class="[skinClasses]"
  >
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from "@themeConfig"
import { provideToast } from "vue-toastification/composition"
import { watch } from "@vue/composition-api"
import useAppConfig from "@core/app-config/useAppConfig"

import { useWindowSize, useCssVar } from "@vueuse/core"

import store from "@/store"
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import useJwt from "@/auth/jwt/useJwt";
import SecurityService from "@/auth/auth";

const LayoutVertical = () => import("@/layouts/vertical/LayoutVertical.vue");
const LayoutHorizontal = () =>
  import("@/layouts/horizontal/LayoutHorizontal.vue");
const LayoutFull = () => import("@/layouts/full/LayoutFull.vue");

let _this;

export default {
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === "full") return "layout-full";
      return `layout-${this.contentLayoutType}`;
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type;
    },
  },
  created() {
    _this = this;
    // this.setNotification();
    this.$http.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        console.log(error);
        if (error.response.status === 401) {
          if (error.response.data.ErrorMessage && error.response.data.ErrorMessage.indexOf("Refresh token is not valid") !== -1) {
            SecurityService.prototype.logout()
          }
          console.log('Response Status 401, redirect placeholder');
          // window.location.href = '/auth/login';
        }
        return Promise.reject(error);
      }
    );

    this.$http.interceptors.request.use(
      (config) => {
        var authToken = this.$cookies.get('CMToken');
        if (authToken) {
          config.headers['Authorization'] = 'BEARER ' + this.$cookies.get('CMToken');
        }
        return config;
      },
      (error) => Promise.reject(error)
    );
  },
  beforeCreate() {
    // Set colors in theme
    const colors = [
      "primary",
      "secondary",
      "success",
      "info",
      "warning",
      "danger",
      "light",
      "dark",
    ];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement
      ).value.trim();
    }

    // Set Theme Breakpoints
    const breakpoints = ["xs", "sm", "md", "lg", "xl"];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement
        ).value.slice(0, -2)
      );
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout;
    document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr");
  },
  methods: {
    // async setNotification() {
    //   if (Number(localStorage.getItem('isLoggedIn')) && navigator.onLine) {
    //     axios.get('/api/notifications/user')
    //         .then( res => {
    //           store.commit('notification/setNotification', res.data)
    //         })
    //   }
    // },
    showToast(data) {
      _this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Error Info",
              icon: "InfoIcon",
              text: data,
              variant: 'danger',
            },
          },
          {
            position: 'top-center',
            timeout: 4000,
          }
      );
    },
  },
  setup() {
    const { skin, skinClasses } = useAppConfig();

    // If skin is dark when initialized => Add class to body
    if (skin.value === "dark") document.body.classList.add("dark-layout");

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: "Vue-Toastification__fade",
    });

    // Set Window Width in store
    store.commit("app/UPDATE_WINDOW_WIDTH", window.innerWidth);
    const { width: windowWidth } = useWindowSize();
    watch(windowWidth, (val) => {
      store.commit("app/UPDATE_WINDOW_WIDTH", val);
    });

    return {
      skinClasses,
    };
  },
};
</script>
