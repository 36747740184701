import axios from 'axios'
import App from "@/App";
import store from "@/store";
import SecurityService from "@/auth/auth";

const axiosIns = axios.create({
    timeout: 1000 * 120,
})

export const axiosInc = axios;

const catchError = (error) => {
    if(error.response && error.response.data) {
        if (error.response && error.response.data && error.response.data.ErrorMessage) {
            if (error.response.data.ErrorMessage.indexOf("Invalid auth token") !== -1) {
                console.log("Invalid auth token")
                return SecurityService.prototype.logout()
            } else {
                return App.methods.showToast(JSON.stringify(error.response.data.ErrorMessage));
            }
        } else {
            return App.methods.showToast(JSON.stringify(error.response.data));
        }
    } else if(error.__CANCEL__) {
        console.log(error)
    } else if (error.response && error.response.status === 429) {
        return App.methods.showToast("Server too busy at the moment.  Try later.");
    } else if (error.response && error.response.status === 304) {
        return {
            code: 304
        }
    } else {
        return error
    }
}

export default function APIService() {
    this.APIUrl = "/api/"
    this.get = function (path, signal = null) {
        return this.request("GET", path, null, signal)
            .catch(error => catchError(error))
    }
    this.getUser = () => {
        return this.request("GET", 'users/user')
            .catch(error => catchError(error))
    }
    this.post = (path, data, headers = null) => {
        return this.request("POST", path, data, headers)
            .catch(error => catchError(error))
    }
    this.put = (path, data) => {
        return this.request("PUT", path, data)
            .catch(error => catchError(error))
    }
    this.delete = (path) => {
        return this.request("DELETE", path)
            .catch(error => catchError(error))
    }
    this.request = (method, path, data = null, headers = null) => {
        store.commit('users/setBackendActivity', true);
        switch (method) {
            case "GET":
                return axiosIns.get(this.APIUrl + path, headers);
            case "POST":
                return axiosIns.post(this.APIUrl + path, data, headers);
            case "PUT":
                return axiosIns.put(this.APIUrl + path, data);
            case "DELETE":
                return axiosIns.delete(this.APIUrl + path);
            // TODO add default with exception
        }
    }
}
