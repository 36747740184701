import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  users: [
    {
      id: 1,
      avatar: require('@/assets/images/avatars/10-small.png'),
      fullName: "Korrie O'Crevy",
      email: 'kocrevy0@thetimes.co.uk',
      userRole: 'Operation Support',
      status: 'Locked',
    },
    {
      id: 2,
      avatar: require('@/assets/images/avatars/1-small.png'),
      fullName: 'Bailie Coulman',
      email: 'bcoulman1@yolasite.com',
      userRole: 'Account Manager',
      status: 'Active',
    },
    {
      id: 3,
      avatar: require('@/assets/images/avatars/9-small.png'),
      fullName: 'Stella Ganderton',
      email: 'sganderton2@tuttocitta.it',
      userRole: 'Support',
      status: 'Locked',
    },
    {
      id: 4,
      avatar: require('@/assets/images/avatars/3-small.png'),
      fullName: 'Dorolice Crossman',
      email: 'dcrossman3@google.co.jp',
      userRole: 'Account Manager',
      status: 'Active',
    },
    {
      id: 5,
      avatar: require('@/assets/images/avatars/4-small.png'),
      fullName: 'Harmonia Nisius',
      email: 'hnisius4@gnu.org',
      userRole: 'Operation Support',
      status: 'Locked',
    },
    {
      id: 6,
      avatar: require('@/assets/images/avatars/5-small.png'),
      fullName: 'Genevra Honeywood',
      email: 'ghoneywood5@narod.ru',
      userRole: '06/01/2017',
      status: 'Current',
    },
    {
      id: 7,
      avatar: require('@/assets/images/avatars/7-small.png'),
      fullName: 'Eileen Diehn',
      email: 'ediehn6@163.com',
      userRole: '10/15/2017',
      status: 'Locked',
    },
    {
      id: 8,
      avatar: require('@/assets/images/avatars/9-small.png'),
      fullName: 'Richardo Aldren',
      email: 'raldren7@mtv.com',
      userRole: 'Investigator',
      status: 'Rejected',
    },
    {
      id: 9,
      avatar: require('@/assets/images/avatars/2-small.png'),
      fullName: 'Allyson Moakler',
      email: 'amoakler8@shareasale.com',
      userRole: 'Support',
      status: 'Active',
    },
    {
      id: 10,
      avatar: require('@/assets/images/avatars/6-small.png'),
      fullName: 'Merline Penhalewick',
      email: 'mpenhalewick9@php.net',
      userRole: 'Operation Support',
      status: 'Locked',
    },
  ],
}

mock.onGet('/users/users').reply(() => [200, data.users])
