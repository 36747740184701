export default {
  namespaced: true,
  state: {
    users: {},
    userToEdit: {},
    isBackendActivity: false,
  },
  getters: {
    getCurrentUsers: state => state.users,
    getUserToEdit: state => state.userToEdit,
    getBackendActivity: state => state.isBackendActivity,
  },
  mutations: {
    setCurrentUsers(state, data) {
      state.users = { ...data }
    },
    setUserToEdit(state, data) {
      state.userToEdit =  { ...data }
    },
    setBackendActivity(state, data) {
      state.isBackendActivity = data
    },
  },
  actions: {
    // async operations
    async fetchUsers(state, cookie) {
      const response = await fetch('/api/users/user ', { headers: { Authorization: `Bearer ${cookie}` } })
      const data = await response.json()
      state.commit('setCurrentUsers', data)
    },
  },
}
