export default {
    namespaced: true,
    state: {
        videoTimer: "00:00",
        editMode: false,
    },
    getters: {
        getVideoTime: state => state.videoTimer,
        getEditMode: state => state.editMode,
    },
    mutations: {
        setVideoTime(state, data) {
            state.videoTimer = data
        },
        setEditMode(state, data) {
            state.editMode = data
        },
    },
}
