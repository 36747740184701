/* eslint-disable no-console */

// only uncomment next line for testing locally on Prod build
// must run
// set BASE_URL=/
// set NODE_ENV=production
// set NODE_OPTIONS="--openssl-legacy-provider"
// npm run build
// npm run serveProd
// test in Chrome incognito

import { register } from 'register-service-worker'

const unregisterServiceWorker = () => {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    for (let registration of registrations) {
      registration.unregister().then((boolean) => {
        if (boolean) {
          console.log('Service Worker unregistered successfully');
        } else {
          console.log('Service Worker unregistration failed');
        }
      });
    }
  }).catch((error) => {
    console.error('Error during Service Worker unregistration:', error);
  });

  // delete all caches
  caches.keys().then((cacheNames) => {
    return Promise.all(
        cacheNames.map((cacheName) => {
          return caches.delete(cacheName);
        })
    );
  }).then(() => {
    console.log('All caches deleted');
  }).catch((error) => {
    console.error('Error during cache deletion:', error);
  });
}

const registerServiceWorker = () => {
  register(`${process.env.BASE_URL}service-worker-cache.js`, {
    ready () {
      console.log(
          'App is being served from cache by a service worker.\n' +
          'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated () {
      console.log('New content is available; please refresh.');
      navigator.serviceWorker.controller.postMessage({ type: 'SKIP_WAITING'});
      // window.location.reload(true);
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
      alert('You are now off the network.  Certain features such as uploading media and video are unavailable.');
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}

if (
(process.env.NODE_ENV === 'production')
)
// comment next line while testing   
//  (process.env.NODE_ENV === 'donotload')
{
  registerServiceWorker()
}
// if we do not register service worker, then stop prior registrations
else if ('serviceWorker' in navigator) {
  unregisterServiceWorker()
}
