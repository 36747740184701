export default {
    namespaced: true,
    state: {
        Notification: [],
    },
    getters: {
        getNotification: state => state.Notification,
    },
    mutations: {
        setNotification(state, val) {
            state.Notification = val
        },
    },
    actions: {},
}
