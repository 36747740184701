export default {
  namespaced: true,
  state: {
    allUsers: {},
  },
  getters: {
    getAllUsers: state => state.users,
  },
  mutations: {
    setAllUsers(state, users) {
      state.allUsers = users
    },
  },
  actions: {
    // async operations
    async fetchAllUsers(state, cookie) {
      const invResponse = await fetch('api/users/investigator', { headers: { Authorization: `Bearer ${cookie}` } })
      const invData = await invResponse.json()

      const cliResponse = await fetch('api/users/client', { headers: { Authorization: `Bearer ${cookie}` } })
      const cliData = await cliResponse.json()

      const response = await fetch('/api/users/user ', { headers: { Authorization: `Bearer ${cookie}` } })
      const userData = await response.json()

      const destructuredUsers = JSON.parse(JSON.stringify(userData))
      const destructuredClients = JSON.parse(JSON.stringify(cliData))
      const destructuredInvestigators = JSON.parse(JSON.stringify(invData))
      // this is crazy below TD - working thru data to figure out how to join the data to view in UI it might have to be done on the API side AJ
      const userValues = Object.values(destructuredUsers.users)
      const clientValues = Object.values(destructuredClients.clients)
      const investigatorValues = Object.values(destructuredInvestigators.investigators)
      const newSetUsers = userValues[3].map(data => ({
        ID: data.UserAccountID,
        FirstName: data.FirstName,
        LastName: data.LastName,
        EMail: data.EMail,
        Status: data.Status,
        UserType: data.UserType,
      }))
      const newSetInvestegators = investigatorValues[3].map(data => ({
        ID: data.UserAccountID,
        FirstName: data.FirstName,
        LastName: data.LastName,
        EMail: data.EMail,
        Status: data.Status,
        UserType: data.UserType,
      }))
      const newSetClients = clientValues[3].map(data => ({
        ID: data.UserAccountID,
        FirstName: data.FirstName,
        LastName: data.LastName,
        EMail: data.EMail,
        Status: data.Status,
        UserType: data.UserType,
      }))
      const unMutatedUsers = newSetUsers.concat(newSetInvestegators, newSetClients)
      state.commit('setAllUsers', unMutatedUsers)
    },
  },
}
