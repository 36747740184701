import Oidc  from 'oidc-client-ts';
import {
    clientRoot,
    loginCallback,
    logoutCallback
} from '@/config/config';
import store from '@/store';
import axios from 'axios';
import Role from '../models/roles';
import APIService from "@core/utils/APIService";

const apiService = new APIService();
var oidcClient = {}

function setupOidcClient({
    authority,
    clientId,
    scope,
    responseType,
}) {
    localStorage.setItem('oidc_authority', authority);
    localStorage.setItem('oidc_clientId', clientId);
    localStorage.setItem('oidc_scope', scope);
    localStorage.setItem('oidc_responseType', responseType);

    oidcClient = new Oidc.UserManager({
        userStore: new Oidc.WebStorageStateStore(),
        authority: authority,
        client_id: clientId,
        redirect_uri: clientRoot + loginCallback,
        response_type: responseType,
        scope: scope,
        post_logout_redirect_uri: clientRoot + logoutCallback,
        filterProtocolClaims: true,
        loadUserInfo: true,
    })

    Oidc.Log.logger = console;
    Oidc.Log.level = Oidc.Log.INFO;

    oidcClient.events.addUserLoaded(function(user) {
        console.log('New User Loaded：', arguments);
        console.log('Acess_token: ', user.access_token);
    });

    oidcClient.events.addAccessTokenExpiring(function() {
        console.log('AccessToken Expiring：', arguments);
    });

    oidcClient.events.addAccessTokenExpired(function() {
        console.log('AccessToken Expired：', arguments);
        //alert('Session expired. Going out!');
        oidcClient.signoutRedirect().then(function(resp) {
            console.log('signed out', resp);
        }).catch(function(err) {
            console.log(err)
        })
    });

    oidcClient.events.addSilentRenewError(function() {
        console.error('Silent Renew Error：', arguments);
    });

    oidcClient.events.addUserSignedOut(function() {
        //alert('Going out!');
        console.log('UserSignedOut：', arguments);
        oidcClient.signoutRedirect().then(function(resp) {
            console.log('signed out', resp);
        }).catch(function(err) {
            console.log(err)
        })
    });
}

if (localStorage.getItem('oidc_authority')) {
    var authSettings = {
        authority: localStorage.getItem('oidc_authority'),
        clientId: localStorage.getItem('oidc_clientId'),
        scope: localStorage.getItem('oidc_scope'),
        responseType: localStorage.getItem('oidc_responseType'),
    }

    setupOidcClient(authSettings);
}

export default class SecurityService {

    startOfSession;

    async login(email) {
        try {
            var result = await axios.post('/api/auth/getauthsettings', { email });
            const url = new URL(result.data.authRedirectUrl)
            const authority = url.origin
            const clientId = url.searchParams.get('client_id')

            if (authority.indexOf("auth0") !== -1) {
                localStorage.setItem('authority', authority);
                localStorage.setItem('clientId', clientId);
            }

            window.location = result.data.authRedirectUrl;

            // setupOidcClient(result.data.authSettings);

            // return oidcClient.signinRedirect({
            //   extraQueryParams: {
            //     login_hint: email
            //   }
            // }); // Returns promise to trigger a redirect of the current window to the authorization endpoint.
        } catch (error) {
            console.log(error);
            return error.response.data;
        }
    }

    async isLoggedIn() {
        // const user = oidcClient && typeof oidcClient.getUser == 'function' && await oidcClient.getUser();
        // // expired did not seem to be returning a value currently : not sure if this is unexpected
        // const userCurrent = !!user && !user.expired;

        // return userCurrent;
        if (store.state.auth.sessionEnds) return true
        if (window.location.href.indexOf("cases/add/public") !== -1) return false
        if (window.location.href.indexOf("cases/add/public2") !== -1) return false
        if (window.location.href.indexOf("video/view/pub") !== -1) return false
        if (!navigator.onLine) return true

        try {
            var result = await axios.get('/api/auth/status');
            if (result.data.expires > 0) {
                var establishedDate = new Date(result.data.established);
                localStorage.setItem('session-start', establishedDate);
                localStorage.setItem('userType',  result.data.userType);
                localStorage.setItem('userRole',  result.data.userRole);
                localStorage.setItem('expires',  result.data.expires);
                localStorage.setItem('sessionTimeout',  result.data.sessionTimeout);
                store.commit('auth/UPDATE_SESSION_START', establishedDate);
                store.commit('auth/setUserType', result.data.userType);
                store.commit('auth/setUserRole', result.data.userRole);
                store.commit('auth/setLoginStatus', true);
                store.commit('auth/setExpires', result.data.expires);

                axios.get('/api/auth/scopes').then(scopesResponse => {
                    store.commit('scopes/setCurrentScopes', scopesResponse.data);
                })
                .catch(err => {
                    store.commit('scopes/setCurrentScopes', []);
                });
                if (!localStorage.EMail || !localStorage.UID) {
                    apiService.get('user/profile').then(res => {
                        localStorage.setItem('EMail', res.data.EMail);
                        localStorage.setItem('UID', res.data.UserAccountID);
                    })
                }
                localStorage.setItem('isLoggedIn',  '1');
                return true;
            }

            store.commit('scopes/setCurrentScopes', []);
            store.commit('auth/setLoginStatus', false);
            localStorage.setItem('isLoggedIn',  '0');
            return false;
        } catch (error) {
            console.log(error);
            store.commit('scopes/setCurrentScopes', []);
            store.commit('auth/setLoginStatus', false);
            localStorage.setItem('isLoggedIn',  '0');
            return false;
        }
    }

    async completeLogin() {
        const user = await oidcClient.signinRedirectCallback() // Returns promise to process response from the authorization endpoint. The result of the promise is the authenticated User
        ;
        try {
            // these there lines below need to be removed after next sprint TD Aj
            console.log(user);
            localStorage.setItem('acces-token', user.access_token)
            localStorage.setItem('id-token', user.id_token)

            var response = await axios.post('/api/auth/login', { accessToken: user.access_token, idToken: user.id_token });
            if (response && response.data) {
                var usr = response.data;
                console.log('response data ', usr)
                localStorage.setItem('role', Role.ADMIN)
                localStorage.setItem('userName', `${usr.firstName} ${usr.lastName}`);
                localStorage.setItem('userType', `${usr.userType}`);
                localStorage.setItem('userRole', `${usr.userRole}`);
                // Need to refine where this is set, to more of when the dashboard renders
                await this.setStartOfSession();
                store.commit('auth/setUserType', usr.userType)
                store.commit('auth/setUserRole', usr.userRole)
                return user;
            }
        } catch {}
        return { error: 'We could not verify your login with the server.' }
    }

    async logout() {
        if (!navigator.onLine) return
        const returnTo = clientRoot + logoutCallback;
        const authority = localStorage.getItem('authority');
        const clientId = localStorage.getItem('clientId');
        try {
            if (authority && clientId) {
                const url = `${authority}/v2/logout?returnTo=${returnTo}&client_id=${clientId}`;
                localStorage.clear();
                this.completeLogout();
                window.location = url;
            } else {
                var result = await axios.get('/api/auth/logout');
                localStorage.clear();
                this.completeLogout();
                window.location = '/';
            }
        } catch (error) {
            console.log(error);
            return false;
        }
    }

    completeLogout() {
        localStorage.removeItem('authority');
        localStorage.removeItem('clientId');
        localStorage.removeItem('oidc_authority');
        localStorage.removeItem('oidc_clientId');
        localStorage.removeItem('oidc_scope');
        localStorage.removeItem('oidc_responseType');
        localStorage.removeItem('userName');
        localStorage.removeItem('userType');
        localStorage.removeItem('userRole');
        localStorage.removeItem('EMail');
        localStorage.removeItem('UID');
        store.commit('scopes/setCurrentScopes', []);
    }

    async getAccessToken() {
        const user = await oidcClient.getUser(); // Returns promise to load the User object for the currently authenticated user.
        return !!user && !user.expired ? user.access_token : null
    }

    async setStartOfSession() {
        // console.log('start of session was', this.startOfSession)
        this.startOfSession = new Date();
        localStorage.setItem('session-start', this.startOfSession)
        store.commit('auth/UPDATE_SESSION_START', this.startOfSession)
            // console.log('start of session is now', this.startOfSession)
    }
}
