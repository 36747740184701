<template>
  <div />
</template>

<script>
import { useToast } from 'vue-toastification/composition'
import axios from 'axios';

export default {
  name: 'OidcSignOutCallBack',
  setup() {
    return {
      toastNotification: useToast(),
    }
  },
  async created() {
    const result = await axios.get('/api/auth/logout');
    if (result) {
        this.$oidc.completeLogout()
        await this.$router.push('/')
    }
  },
}
</script>
