import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import axios from 'axios'
import ToggleButton from 'vue-js-toggle-button'
import VDraggable from 'vue-sortable-list'

import VueCookies from 'vue-cookies'
import router from './router'
import store from './store'
import App from './App.vue'

import { OhVueIcon, addIcons } from "oh-vue-icons";
import { FaCalendarTimes } from "oh-vue-icons/icons";
import ProgressBar from 'vuejs-progress-bar'

addIcons(FaCalendarTimes);

Vue.component("v-icon", OhVueIcon);

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

import Oidc from './auth/auth'
// Axios Mock Adapter
import '@/@fake-db/db'

import VueFileAgent from 'vue-file-agent'
import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css'

import VueMask from 'v-mask'
Vue.use(VueMask);

// Disable service worker
import './registerServiceWorker'

Vue.use(VueFileAgent);

// to grab cookie aj
Vue.use(VueCookies)
// Draggable list
Vue.use(VDraggable);

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(ToggleButton)

// Composition API
Vue.use(VueCompositionAPI)
Vue.use(require('vue-moment'))

Vue.use(ProgressBar)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.prototype.$oidc = new Oidc()

Vue.prototype.$http = axios

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')