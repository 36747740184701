export default {
    namespaced: true,
    state: {
        MainSearchCategory: "",
        MainSearchItem: "",

        ClaimsFilter: "",
        
        ClientsSearchName: "",
        ClientsFilter: "",

        InsuredSearch: "",
        InsuredFilter: "",

        showMainSearch: false,
    },
    getters: {
        getMainSearchCategory: state => state.MainSearchCategory,
        getMainSearchItem: state => state.MainSearchItem,
        getClaimsFilter: state => state.ClaimsFilter,
        getClientsSearchName: state => state.ClientsSearchName,
        getClientsFilter: state => state.ClientsFilter,
        getInsuredSearch: state => state.InsuredSearch,
        getInsuredFilter: state => state.InsuredFilter,
        getShowMainSearch: state => state.showMainSearch,

    },
    mutations: {
        setMainSearchCategory(state, data) {
            state.MainSearchCategory = data
        },
        setMainSearchItem(state, data) {
            state.MainSearchItem = data
        },
        setClaimsFilter(state, data) {
            state.ClaimsFilter = data
        },
        setClientsSearchName(state, data) {
            state.ClientsSearchName = data
        },
        setClientsFilter(state, data) {
            state.ClientsFilter = data
        },
        setInsuredSearch(state, data) {
            state.InsuredSearch = data
        },
        setInsuredFilter(state, data) {
            state.InsuredFilter = data
        },
        setShowMainSearch(state, data) {
            state.showMainSearch = data
        },
    },
    actions: {},
}
