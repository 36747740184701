export default {
    namespaced: true,
    state: {
        online: navigator.onLine,
    },
    getters: {
        getOnlineStatus: state => state.online,
    },
    mutations: {
        setOnlineStatus(state, data) {
            const { type } = data;
            state.online = type === 'online';
        },
    },
}
