export default {
  namespaced: true,
  state: {
    UserScopes: localStorage.getItem("UserScopes") || [],
  },
  getters: {
    getCurrentScopes: state => state.UserScopes,
  },
  mutations: {
    setCurrentScopes(state, data) {
      localStorage.setItem("UserScopes", JSON.stringify(data))
      state.UserScopes = data;
    },
  },
  actions: {
    // async operations
    async fetchScopes(state, cookie) {
      const response = await fetch('/api/auth/scopes', { headers: { Authorization: `Bearer ${cookie}` } })
      const data = await response.json()
      state.commit('setCurrentScopes', data)
    },
  },
}
